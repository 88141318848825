@font-face {
    font-family: 'Kinga';
    src: url('Kinga-Regular.woff2') format('woff2'),
        url('Kinga-Regular.woff') format('woff'),
        url('Kinga-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

